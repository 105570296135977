<template>
  <div id="loc" class="uk-margin-large-top">
      <h2 class="uk-heading-line uk-text-center"><span>Lokalizacja</span></h2>
      <div v-html="frame">
      </div>
  </div>
</template>

<script>
export default {
    name:"Localization",
    props: ["frame"]
}
</script>

<style>

</style>