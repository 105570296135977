<template>
    <div id="contact" class="uk-margin-xlarge-top mp-block">
        <div class="uk-card uk-grid-collapse uk-child-width-1-2@s uk-margin uk-margin-remove-bottom" uk-grid uk-scrollspy="'cls: uk-animation-slide-right'"
>
            <div class="uk-card-media-left uk-cover-container uk-margin-large-top">
                <a href="https://nieruchomosci.buszrem.pl/"><img class="uk-responsive-width uk-responsive-height" :src="logo" alt="Nieruchomości buszrem.pl"></a>
            </div>
            <div>
                <div class="uk-card-body">
                    <h2 class="uk-card-title uk-text-lead uk-text-bold">Biuro Sprzedaży Mieszkań Warszawa Włochy</h2>
                    <p class="uk-text-lead">ul. Kłosia 8, 02-466 Warszawa</p>
                    <p class="uk-text-lead"><a href="tel:224024656">22 402 46 56</a>; <a href="tel:228634404">22 863 44 04</a></p>
                    <p class="uk-text-lead"><a href="mailto:mieszkania@buszrem.pl">mieszkania@buszrem.pl</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    created() {
        this.getLogo()
    },
    methods: {
        async getLogo() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/media/3741').then(res => this.logo = res.data.source_url)
        }
    },
    data() {
        return {
            logo: ''
        }
    }
}
</script>

<style scoped>
.mp-block{
    background: #ec6a29;;
}
</style>