<template>
    <div>
        <nav class="uk-navbar-container" uk-navbar>
    <div class="uk-navbar-left uk-margin-small-top uk-margin-small-bottom">

        <a class="uk-navbar-item uk-logo uk-margin-medium-left uk-hidden@m" href="#"><img :src="logo" width="200px" height="auto" alt="Buszrem Nieruchomości" uk-img></a>
    </div>
    <div class="uk-navbar-right">
        <ul class="uk-navbar-nav uk-visible@m uk-margin-medium-right">
                <li><a class="mp-men" href="#desc">Opis</a></li>
                <li><a class="mp-men" href="#spacer">Spacer</a></li>
                <li><a class="mp-men" href="#offer">Oferta</a></li>
                <li><a class="mp-men" href="#plan">Plan</a></li>
                <li><a class="mp-men" href="#loc">Lokalizacja</a></li>
                <li><a class="mp-men" href="#contact">Kontakt</a></li>
        </ul>
        <button class="uk-navbar-toggle uk-hidden@m" uk-navbar-toggle-icon uk-toggle="target: #my-id"></button>
    </div>
</nav>
        <!-- <nav class="uk-navbar-container uk-navbar-transparent uk-margin-small-top" uk-navbar>
    <div class="uk-navbar-center">

        <div class="uk-navbar-center-left"><div>
            
        </div></div>
        
        <div class="uk-navbar-center-right"><div>
            <ul class="uk-navbar-nav">
                <li><a class="mp-men" href="#loc">Lokalizacja</a></li>
                <li><a class="mp-men" href="#contact">Kontakt</a></li>
            </ul>
        </div></div>

    </div>
</nav> -->
    </div>
</template>

<script>
export default {
    name: "Header",
    props: ["logo"]
}
</script>

<style scoped>
.mp-men {
    font-size: 1rem;
}
.mp-men:hover{
    color: #ec6a29;
}
</style>