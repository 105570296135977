<template>
  <div id="spacer" class="uk-margin-xlarge-top">
      <h2 class="uk-heading-line uk-text-center"><span>Spacer</span></h2>
      <div v-html="spacer">
      </div>
  </div>
</template>

<script>
export default {
    props: ['spacer']
}
</script>

<style>

</style>