<template>
  <div id="offer" class="uk-overflow-auto uk-margin-auto uk-margin-xlarge-top uk-container-large">
      <h2 class="uk-heading-line uk-text-center"><span>Lista mieszkań</span></h2>
      <v-simple-table
        fixed-header
        height="600px"
    >
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="(item, index) in getHedersTab" :key="index">{{item}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in getBody" :key="index">
                <td v-for="(it, index) in item" :key="index" v-html="it"></td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
    <!-- <table class="uk-table uk-table-small uk-table-divider">
        <thead>
            <tr>
                <th v-for="(item, index) in getHedersTab" :key="index">{{item}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in getBody" :key="index">
                <td v-for="it in item" :key="it" v-html="it"></td>
            </tr>
        </tbody>
    </table> -->
</div>
</template>

<script>
export default {
    name: "Table",
    props: ['table'],
    computed: {
        getHedersTab()
        {
            return this.table[0];
        },
        getBody() {
            return this.table.slice(1);
        }
    }
}
</script>

<style>

</style>