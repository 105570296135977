import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false
Vue.use(VueMeta)

axios.get('/static/config.json').then(res => {
  axios.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/posts/' + res.data["postid"]).then(res => {
    Vue.prototype.$post = res.data;
    Vue.prototype.$http = axios;
    Vue.prototype.$crs = L.CRS.Simple;
    new Vue({
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }).catch(err => console.log(err))
}).catch(err => console.log(err))
