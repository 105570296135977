<template>
    <div class="uk-inline uk-visible@m">
        <img :src="img" width="100%" :alt="title" uk-img>
        <div class="uk-position-large uk-position-top-center mp-title"><img :src="logo" width="200px" height="auto" alt="Buszrem Nieruchomości" uk-img></div>
    </div>
</template>

<script>
export default {
    name: "HeaderImg",
    props: ['img', 'logo', 'title']
}
</script>

<style scoped>
.mp-title{
    font-size: 5rem;
    color: #ec6a29;
    text-shadow: 10px 10px 15px black;
}
</style>