<template>
  <div class="uk-margin-large-top">
      <h2 class="uk-heading-line uk-text-center"><span>Plan zagospodarowania</span></h2>
      <div class="uk-position-relative">
          <img id="im" :src="plane" alt="Buszrem plan" usemap="#image-map"/>
          <!-- <canvas v-for="map in maps" :key="map.number" :id="map.number" class="mp-vis uk-position-absolute" :class="(map.number === hoveredSubjectId) ? '':'uk-hidden'" :width="width" :height="height"/> -->
          <map class="uk-hidden" name="image-map">
            <area v-for="map in maps" :key="map.number" class="mp" :alt="map.number" :title="map.number" :coords="map.cords" :shape="map.shape">
            <!-- @mouseover="hoverToggle(map.number, 'mouseOver', $event)"
            @mouseleave="hoverToggle(map.number, 'mouseLeave')" -->
        </map>
      </div>
      <div id="tip" class="uk-position-absolute uk-hidden" style="left: 0px; top: 0px;">
          <div class="uk-card uk-card-default uk-card-body">
                <h3 class="uk-card-title">Lokal: {{tab[0]}}</h3>
                <p>
                    <table class="uk-table uk-table-small uk-table-divider">
                        <tr>
                            <td>
                                Metraż (m<sup>2</sup>)
                            </td>
                            <td>
                                {{tab[2]}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ogród (m<sup>2</sup>)
                            </td>
                            <td>
                                {{tab[3]}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Liczba pokoi
                            </td>
                            <td>
                                {{tab[1]}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Status
                            </td>
                            <td :class="getClass(tab[5])">
                                {{tab[5]}}
                            </td>
                        </tr>
                    </table>
                </p>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "Plan",
    props: ["plane", "table"],
    mounted() {
        this.getCoords();
    },
    updated() {
        this.updateHeight();
    },
    methods: {
        async getCoords() {
            return await this.$http.get('/static/coords.json').then(res => {this.maps = res.data})
        },
        getClass(obj) {
            console.log(obj);
            if(obj === 'SPRZEDANY' || obj === 'SPRZEDANE'){
                return 'col-red';
            } else if(obj === 'REZERWACJA') {
                return 'col-orange';
            } else if (obj === 'WOLNY' || obj === 'WOLNE') {
                return 'col-green';
            }
        },
        overObj(subjectId, y, x) {
            let modal = document.getElementById("tip");
            this.tab = this.table.find(el => el[0] == subjectId);
            modal.style.top = (y + 50) + "px";
            modal.style.left = (x - 50) + "px";
            document.getElementById(subjectId).classList = "mp-vis uk-position-absolute";
            modal.classList = "uk-position-absolute";
        },
        leaveObj() {
            let modal = document.getElementById("tip");
            document.getElementById(this.tab[0]).classList = "mp-vis uk-position-absolute uk-hidden";
            modal.classList = "uk-position-absolute uk-hidden";
        },
        updateHeight() {
            if(document.getElementById("im").clientWidth !== 0)
            {
                let img = document.getElementById("im");
                if(this.isUpdated == false){
                    this.width = img.clientWidth;
                    this.height = img.clientHeight;
                    this.naturalHeight = img.naturalHeight/img.clientHeight;
                    this.naturalWidth = img.naturalWidth/img.clientWidth;
                    this.maps.forEach(element => {
                        let canvas = document.createElement("canvas");
                        canvas.id = element.number;
                        canvas.width = this.width;
                        canvas.height = this.height;
                        canvas.classList = "mp-vis uk-position-absolute uk-hidden"
                        img.after(canvas);
                    });

                    this.createCanvasItem();
                }
            }
            else {
                setTimeout(() => {
                    this.updateHeight();
                }, 300);
                
            }
        },
        createCanvasItem() {
            this.maps.forEach(element => {
                let canvas = document.getElementById(element.number);
                let ctx = canvas.getContext('2d');
                let temp = [];
                ctx.fillStyle = 'rgba(253, 128, 2, 0.8)';
                if (element.shape == 'poly') {
                    const coordsArray = element.cords.split(',').map(x => +x);
                    coordsArray.forEach((it, index) => {
                        if(index%2 == 0) {
                            temp.push((it/this.naturalWidth).toFixed());
                        } else {
                            temp.push((it/this.naturalHeight).toFixed());
                        }
                    }
                    );
                    const countPoly = (temp.length - 2) / 2;
                    ctx.beginPath();
                    //ctx.strokeStyle = 'blue';
                    ctx.moveTo(temp[0], temp[1]);
                    let nr = 2;
                    for (let i = 0; i < countPoly; i++) {
                        ctx.lineTo(temp[nr], temp[nr + 1]);
                        nr += 2;
                    }
                    ctx.closePath();
                    ctx.fill();
                    element.cords = temp.join(',');
                }

            });
            this.isUpdated = true;
        },
        hoverToggle(subjectId, action, ev) {
            console.log(ev);
            console.log(subjectId);
            console.log(this.tab);
            this.tt = !this.tt;

            console.log(this.tt);
            // if(action === 'overSection') {
            //     this.leaveObj();
            //     this.tab = [];
            // }
            if(this.tab.length > 0 && this.tab[0] !== subjectId) {
                this.leaveObj();
                this.overObj(subjectId, ev.offsetY, ev.offsetX);
            }
            if(this.tab.length === 0 && subjectId) {
                this.overObj(subjectId);
            }
            // switch(action){
            // case 'mouseOver':
            //     this.tab = this.table.find(el => el[0] == subjectId);
            //     modal.style.top = (ev.offsetY + 50) + "px";
            //     modal.style.left = (ev.offsetX - 50) + "px";
            //     document.getElementById(subjectId).classList = "mp-vis uk-position-absolute";
            //     modal.classList = "uk-position-absolute";
            //     break;
            // case 'mouseLeave':
            //     document.getElementById(subjectId).classList = "mp-vis uk-position-absolute uk-hidden";
            //     modal.classList = "uk-position-absolute uk-hidden";
            //     break;
            // }
        }
    },
    data() {
        return {
            maps: {},
            width: 0,
            height: 0,
            naturalHeight: 0,
            naturalWidth: 0,
            hoveredSubjectId: '',
            isUpdated: false,
            dialog: false,
            tab: [],
            tt: false,
        }
    }
}
</script>

<style>
.mp-vis{
    left: 0;
}
.mp{
    cursor: pointer;
}
.tip {
    z-index: 10000000;
}
.col-green{text-align:center;background:lightgreen;padding: 10px; border-radius: 20px;}
.col-red{text-align:center;background:red;padding: 10px; border-radius: 20px;}
.col-orange{text-align:center;background:orange;padding: 10px; border-radius: 20px;}
</style>