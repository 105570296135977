<template>
  <div id="app">
    <div class="top_baner" v-html="this.$post.acf.baner_top">
    </div>
    <div>
      <Header :logo="this.$post.acf.logo_inwestycji" :spacer="(this.$post.block_data[0].attrs.data.spacer) ? true : false"/>
      <HeaderImg :img="imgSrc" :logo="this.$post.acf.logo_inwestycji" :title="this.$post.title.rendered"
      />
    </div>
    <Cards :blocks="this.$post.block_data[0].attrs"/>
    <Spacer v-if="this.$post.block_data[0].attrs.data.spacer" :spacer="this.$post.block_data[0].attrs.data.spacer" />
    <Table :table="this.table" />
    <Plan :plane="this.$post.acf.mapa_inwestycji" :table="this.table" />
    <Paralax :blocks="this.$post.block_data[0].attrs" />
    <Localization :frame="this.$post.block_data[0].attrs.data.lokalizacja" />
    <Footer />
     <div id="my-id" uk-offcanvas="mode: push">
        <div class="uk-offcanvas-bar uk-flex uk-flex-column">

            <button class="uk-offcanvas-close" type="button" uk-close></button>

              <ul class="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
                  <li><a class="mp-men" href="#desc">Opis</a></li>
                <li><a class="mp-men" href="#offer">Oferta</a></li>
                <li><a class="mp-men" href="#plan">Plan</a></li>
                <li><a class="mp-men" href="#loc">Lokalizacja</a></li>
                <li><a class="mp-men" href="#contact">Kontakt</a></li>
              </ul>

        </div>
    </div>
  </div>
</template>

<script>
import Header from './component/Header.vue'
import HeaderImg from './component/HeaderImg.vue'
import Cards from './component/Cards.vue'
import Table from './component/Table.vue'
import Paralax from './component/Paralax.vue'
import Footer from './component/Footer.vue'
import Localization from './component/Localization.vue'
import Plan from './component/Plan.vue'
import Spacer from './component/Spacer.vue'

export default {
  name: 'App',
  props: ["postid"],
  components: {
    Header,
    HeaderImg,
    Cards,
    Table,
    Paralax,
    Footer,
    Localization,
    Plan,
    Spacer
  },
  created() {
        this.getImg()
        this.getTable()
    },
    methods: {
        async getImg() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/media/'+this.$post.featured_media).then(res => this.imgSrc = res.data.source_url)
        },
        async getTable() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/tablepress/v1/tab/'+this.$post.block_data[0].attrs.data.tabela)
            .then(res => {this.table = JSON.parse(res.data[0].post_content); this.table.forEach(element => {
              if(element[5] === 'SPRZEDANY' || element[5] === 'SPRZEDANE'){
                element[5]= `<span class="col-red">${element[5]}</span>`
              } else if(element[5] === 'REZERWACJA') {
                element[5]= `<span class="col-orange">${element[5]}</span>`
              } else if (element[5] === 'WOLNY' || element[5] === 'WOLNE') {
                element[5]= `<span class="col-green">${element[5]}</span>`
              }
            });})
        }
    },
    data() {
        return {
            imgSrc: '',
            table: '',
        }
    },
  metaInfo() {
    return {
      title: this.$post._yoast_wpseo_title,
      meta: [
        {
          name: 'description',
          content: this.$post._yoast_wpseo_metadesc
        },
        {
          property: 'og:title',
          content: this.$post._yoast_wpseo_title
        },
        {
          property: 'og:site_name',
          content: 'Szeroka 140'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:description',
          content: this.$post._yoast_wpseo_metadesc
        },
        {
          property: 'og:url',
          contenct: 'test'
        },
        {
          property: 'og:image',
          content: this.imgSrc
        }
      ]
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.top_baner{
  background-color: #ec6a29;
  padding: 10px 0;
  font-weight: bold;
}
.top_baner a {
  font-weight: 500;
}
</style>
