<template>
    <div class="uk-margin-xlarge-top@m">
        <div id="desc" class="uk-card uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid uk-scrollspy="'cls: uk-animation-slide-right'"
>
            <div class="uk-card-media-left uk-cover-container">
                <img class="uk-responsive-width uk-responsive-height" :src="firstImg" alt="" uk-cover>
                <canvas width="600" height="400"></canvas>
            </div>
            <div>
                <div class="uk-card-body">
                    <h2 class="uk-card-title uk-text-lead uk-text-bold">{{blocks.data.opis_gora_pierszwszy_tytul}}</h2>
                    <p v-html="blocks.data.opis_gora_pierszwszy_opis"></p>
                </div>
            </div>
        </div>

        <div class="uk-card uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid uk-scrollspy="'cls: uk-animation-slide-left'"
>
            <div class="uk-flex-last@s uk-card-media-right uk-cover-container">
                <img class="uk-responsive-width uk-responsive-height" :src="secondImg" alt="" uk-cover>
                <canvas width="600" height="400"></canvas>
            </div>
            <div>
                <div class="uk-card-body">
                    <h2 class="uk-card-title uk-text-lead uk-text-bold">{{blocks.data.opis_gora_drugi_tytul}}</h2>
                    <p v-html="blocks.data.opis_gora_drugi_opis"></p>
                </div>
            </div>
        </div>
        <div v-if="blocks.data.opis_gora_trzeci_tytul" id="desc" class="uk-card uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid uk-scrollspy="'cls: uk-animation-slide-right'"
>
            <div class="uk-card-media-left uk-cover-container">
                <img class="uk-responsive-width uk-responsive-height" :src="thirdImg" alt="" uk-cover>
                <canvas width="600" height="400"></canvas>
            </div>
            <div>
                <div class="uk-card-body">
                    <h2 class="uk-card-title uk-text-lead uk-text-bold">{{blocks.data.opis_gora_trzeci_tytul}}</h2>
                    <p v-html="blocks.data.opis_gora_trzeci_opis"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Cards",
    props: ["blocks"],
    data() {
        return {
            firstImg: '',
            secondImg: '',
            thirdImg: ''
        }
    },
    mounted() {
        this.getFirstImg();
        this.getSecondImg();
        this.getThirdImg();
    },
    methods: {
        async getFirstImg() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/media/'+this.blocks.data.opis_gora_pierszwszy_obraz).then(res => { this.firstImg = res.data.source_url})
        },
        async getSecondImg() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/media/'+this.blocks.data.opis_gora_drugi_obraz).then(res => { this.secondImg = res.data.source_url})
        },
        async getThirdImg() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/media/'+this.blocks.data.opis_gora_trzeci_obraz).then(res => { this.thirdImg = res.data.source_url})
        }
    }
}
</script>

<style>

</style>