<template>
  <div uk-height-viewport="min-height: 500" class="uk-background-cover uk-overflow-hidden uk-light uk-flex uk-flex-top uk-margin-large-top" uk-parallax="bgy: -200" :style="style">
    <div class="uk-width-1-2@m uk-text-center uk-margin-auto uk-margin-auto-vertical uk-overlay uk-overlay-primary">
        <h1>{{blocks.data.opis_dol_pierszwszy_tytul}}</h1>
        <p v-html="blocks.data.opis_dol_pierszwszy_opis"></p>
        <p v-html="blocks.data.opis_dol_drugi_opis"></p>
    </div>
</div>
</template>

<script>
export default {
    name: "Paralax",
    props: ["blocks"],
    data() {
        return {
            backImg: ''
        }
    },
    mounted() {
        this.getBackImg();
    },
    methods: {
        async getBackImg() {
            return await this.$http.get('https://nieruchomosci.buszrem.pl/wp-json/wp/v2/media/'+this.blocks.data.opis_dol_pierszwszy_obraz).then(res => { this.backImg = res.data.source_url})
        },
    },
    computed: {
        style() {
            return "background-image: url('" + this.backImg + "');"
        }
    }
}
</script>

<style>

</style>